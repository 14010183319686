const _register = 'https://go.screver.com/capptoo/hx-talks-join';

export const SCREVER_BTQ = {
  seo: {
    title: 'Screver BTQ',
    description: 'Screver Boutique Event: Let’s talk & Co-work',
    image: '',
  },
  place: 'ZURICH',
  date: 'June 21-23',
  time: '3 pm - 6+ pm',
  components: {
    joinBtn: {
      title: 'Join',
      url: _register,
    },
  },
  header: {
    button: {
      title: 'Register',
      url: _register,
    },
  },
  hero: {
    title: 'Screver Boutique Event: Let’s talk & Co-work',
    button: {
      title: 'Join the event',
      url: _register,
    },
    text:
      "<p>Hey there! We're rolling out the red carpet for a unique three-day get-together in Zurich, exclusively for our special clients and close contacts. Imagine a co-working space that's all about brainstorming, solving your challenges, and hey, a whole lot of fun too!</p><p>Fancy a quick dip 🏊‍♀️ in between problem-solving sessions? How about a fresh new hairstyle 💇‍♀️ while discussing business strategies? Or maybe just kicking back to some live music 🎵 or cinema with a delicious plate from our live cooking station 🍣? You've got it!</p><p>Choose any date, pick a time, and plan your day with us. Let's transform challenges into solutions, and work with a sense of fun!</p>",
    train: [
      '3 days',
      'Same program',
      'Join any time',
      '3 days',
      'Same program',
      'Join any time',
      '3 days',
      'Same program',
      'Join any time',
    ],
  },
  answers: {
    title: 'Brainstorm your burning questions with us',
    button: {
      title: 'Register',
      url: _register,
    },
    list: [
      'How can I generate groundbreaking ideas and diversify my business?',
      'How can I increase my leads and lead-to-win ratio?',
      'What is CX as a service, and can my company make use of it?',
      'Why do I not need A.T.Kearney or McKinsey & Company to succeed?',
      'What are the best strategies for implementing agile ways of working?',
      'How can I launch a successful omnichannel marketing campaign?',
      "Why is UI and UX important for my company's success?",
      'What are the key metrics for measuring CX?',
      'What is the best way to launch an NPS or CSAT program?',
      'How can I effectively retain top talent?',
      'Why is implementing a feedback culture a winning formula?',
      'How do I go about creating a Corporate Movie; and how much would it cost?',
    ],
  },
  expect: {
    title: 'What to expect?',
    list: [
      {
        icon: '1.png',
        text:
          '<strong>Informal co-working</strong> with Screver and Capptoo teams',
      },
      {
        icon: '2.png',
        text:
          'Breakout sessions focused on initiating  ideas on <strong>CX, EX, and Pharma challenges</strong>',
      },
      {
        icon: '3.png',
        text:
          'Inspiring <strong>keynote speeches by Christian and Tanja</strong>, guaranteed to spark ideas',
      },
      {
        icon: '4.png',
        text:
          '<strong>Workshops and one-on-one slots</strong> to tackle any challenge that you bring [Book in advance!]',
      },
      {
        icon: '5.png',
        text:
          'Personal coaching tailored for enhancing <strong>presentation and communication skills</strong>',
      },
      {
        icon: '6.png',
        text:
          'Tantalizing <strong>pub food, sliders, BBQ, and drinks</strong> to keep your energy high',
      },
      {
        img: 'carly.png',
        text:
          '<strong>Cinema and live music</strong> featuring the vibrant sounds of electro-funk, deep house, and smooth jazz, performed by Carly Simone. Get ready to groove!',
      },
    ],
  },
  hair: {
    text:
      "<strong>Get ready to have your hair transformed!</strong> We've got top-notch stylists, amazing giveaways, and an atmosphere that will make your hair stand on end (in a good way!)",
  },
  accordion: {
    title:
      'bring your challenges <br> <svg xmlns="http://www.w3.org/2000/svg" width="117" height="82" fill="none" viewBox="0 0 117 82"><path fill="#000" d="M10.03 5.437a5 5 0 0 0-10 0h10Zm-5 39.535h-5a5 5 0 0 0 5 5v-5Zm110.43 3.536a5.001 5.001 0 0 0 0-7.071L83.64 9.617a5 5 0 1 0-7.071 7.071l28.284 28.284-28.284 28.285a5 5 0 1 0 7.07 7.07l31.821-31.82ZM.03 5.437v39.535h10V5.437h-10Zm5 44.535h106.894v-10H5.031v10Z"/></svg> leave with solutions',
    list: [
      {
        title: 'HEXAGON: KEY VISUAL TO PROMOTE NEW SOFTWARE',
        content: {
          banner:
            'https://wp.screver.com/wp-content/uploads/2023/05/Frame-2791.png',
          title: {
            color: '#92D9EA',
            content: 'KEY VISUAL TO PROMOTE NEW SOFTWARE ',
          },
          label:
            'https://wp.screver.com/wp-content/uploads/2023/05/Group-2798.svg',
          logo:
            'https://wp.screver.com/wp-content/uploads/2023/05/Group-2795.png',
          text: `
          <div>
          <p><span>PRODUCT / SERVICE</span></p>
          <p>Proprietary software that accompanies precise measurements tools developed by Hexagon</p>
          </div>

          <div>
            <p><span>CHALLENGE</span></p>
            <p> Hexagon 's software is powerful, yet intricate. Presenting its capabilities in advertising format and promoting it through traditional digital channels presents a significant challenge.</p>
          </div>

          <div>
            <p><span>SOLUTION</span></p>
            <p>Our concept aims to inspire users to see our software as a transformative tool, shaping the future beyond its features.
            </p>

            <p> To achieve this, we 've meticulously designed a captivating key visual showcasing an extraordinary futuristic robot-dog springing forth from the blueprint onto the software screen.</p>

            <p><span><span>#design  #storytelling  #video</span></span></p>
          </div>
        `,
        },
      },
      {
        title: 'BAYER XARELTO: Assist doctors with interactive possibilities',
        content: {
          banner:
            'https://wp.screver.com/wp-content/uploads/2023/05/Group-2799.png',
          title: {
            color: '#CFA0CF',
            content: 'assist doctors with interactive possibilities',
          },
          label:
            'https://wp.screver.com/wp-content/uploads/2023/05/Group-2798.svg',
          logo:
            'https://wp.screver.com/wp-content/uploads/2023/05/image-1213.png',
          text: `
        <div>
          <p><span>PRODUCT / SERVICE</span></p>
          <p>An app for doctors and patients explaining the possible causes and consequences of stroke.</p>
        </div>

        <div>
          <p><span>CHALLENGE</span></p>
          <p>To streamline the explanation of specific heart problems, an interactive app should be developed, benefiting both patients and cardiologists by saving valuable time.</p>
        </div>

        <div> 
          <p><span>SOLUTION</span></p>
          <p> We 've crafted interactive animated explanations illustrating the formation of blood clots and their consequential impact on strokes in brain
          </p>

          <p><span><span style="color: #E087A8;">#cx #mobile #app_development</span></span></p>
        </div>
        `,
        },
      },
      {
        title: '[secret brand]: enhance sales through new customer experience ',
        content: {
          banner:
            'https://wp.screver.com/wp-content/uploads/2023/05/Rectangle-1668.png',
          title: {
            color: '#FF4A15',
            content: 'enhance sales through new customer experience ',
          },
          label:
            'https://wp.screver.com/wp-content/uploads/2023/05/Group-2798.svg',
          logo:
            'https://wp.screver.com/wp-content/uploads/2023/05/Group-2801.png',
          text: `
        <div>
          <p><span>PRODUCT / SERVICE</span></p>
          <p>[non-disclosure dietary supplement brand] for enthusiastic athletes</p>
        </div>

        <div>
          <p><span>CHALLENGE</span></p>
          <p>
            As the supplementary foods market experiences substantial growth, competition intensifies while customer preferences undergo rapid changes. It becomes imperative to obtain an unbiased view of the current customer experience in order to effectively adjust the product-market fit to meet these new demands.
          </p>
        </div>

        <div>
          <p><span>SOLUTION</span></p>
          <p>
            We organized a series of CX workshops and through sessions, we identified customer journeys, touchpoints, and documented valuable insights for upcoming promotion campaigns.
          </p>

          <p><span><span style="color: #E087A8;">#cx# mobile# app_development</span></span></p>
        </div>
        `,
        },
      },
    ],
  },
  program: {
    title: 'program',
    list: [
      {
        time: '9 am - 3 pm',
        event: [
          {
            title: 'Workshops and one-on-ones with us',
            room: '',
            image:
              'https://wp.screver.com/wp-content/uploads/2023/05/e8aea7c7048a5d2e6ae5a880f456ad4e-1.jpeg.webp',
            index: 1,
            description: [
              'Workshop: Engage in a 2-hour brainstorming session with us. <a href="https://go.screver.com/capptoo/scr-btq-workshop" target="_blank" rel="noopener noreferrer">Secure your place&#160;→</a>',
              'One-on-one: Get an hour of personalized consulting. <a href="https://go.screver.com/capptoo/scr-btw-one-on-one" target="_blank" rel="noopener noreferrer">Grab your spot&#160;→</a>',
              'Personal coaching: Enhance your presentation skills with our tailored guidance. <a href="https://go.screver.com/capptoo/scr-btw-wj" target="_blank" rel="noopener noreferrer">Sign up&#160;→</a>',
            ],
          },
        ],
      },
      {
        time: '3 pm - 6 pm',
        event: [
          {
            title: 'Keynotes and thought-provoking breakout sessions',
            room: '',
            image:
              'https://wp.screver.com/wp-content/uploads/2023/05/e8aea7c7048a5d2e6ae5a880f456ad4e-1.jpeg.webp',
            index: 2,
            description: [
              'Dive into captivating discussions around customer experience, employee experience, pharma, design and AI',
            ],
          },
        ],
      },
      {
        time: '6+ pm',
        event: [
          {
            title: 'Networking, casual co-working, and even more fun 😉',
            room: '',
            image: '#',
            index: 3,
            description: [
              'Unwind to live music, indulge in cinema, discover your perfect hairstyle, savor live cooking, and enjoy drinks. Continue all discussions in a relaxed atmosphere.',
            ],
          },
        ],
      },
    ],
  },
  seeyou: {
    title: 'See you in',
    list: [
      'https://wp.screver.com/wp-content/uploads/2023/05/Rectangle-6.png',
      'https://wp.screver.com/wp-content/uploads/2023/05/Rectangle-8.png',
      'https://wp.screver.com/wp-content/uploads/2023/05/Rectangle-9.png',
      'https://wp.screver.com/wp-content/uploads/2023/05/Rectangle-10.png',
    ],
    place: 'aja CLUB',
    location: 'Vulkanstrasse 108b, Zürich, Switzerland',
  },
  team: {
    title:
      'Questions and challenges are welcome. Let’s brainstorm solutions in a friendly and productive atmosphere.',
    list: [
      'christian.png',
      'Tanja.png',
      'Dejan.png',
      'Roma.png',
      'Ira.png',
      'Nastya.png',
      'Krys.png',
      'Wojtek.png',
      'Ana.png',
      'Igor.png',
      'Natasha.png',
      'Mila.png',
      'Marina.png',
      'Max.png',
      'Victoria.png',
      'Ivana.png',
      'Svitlana.png',
      'Krys-1.png',
      'Andy.png',
      'photo.png',
      'photo-1.png',
    ],
  },
  sticky: {
    button: {
      title: 'I’m in!',
      url: _register,
    },
  },
};
