import React from 'react';
import { Helmet } from 'react-helmet';

import Header from 'sections/ScreverBtq/Header';
import Hero from 'sections/ScreverBtq/Hero';
import Answers from 'sections/ScreverBtq/Answers';
import Expect from 'sections/ScreverBtq/Expect';
import Hair from 'sections/ScreverBtq/Hair';
import Accordion from 'sections/ScreverBtq/AccordionSec';
import Program from 'sections/ScreverBtq/Program';
import SeeYou from 'sections/ScreverBtq/SeeYou';
import Team from 'sections/ScreverBtq/Team';
import Footer from 'sections/ScreverBtq/Footer';
import Sticky from 'sections/ScreverBtq/components/Sticky';

import useIntersection from 'hooks/useIntersection';
import { SCREVER_BTQ } from 'mocks/screver-btq.js';

import { pageWrap } from 'styles/pages/screver-btq.module.scss';

const ScreverBtq = () => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.1,
    triggerOnce: false,
  });

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={SCREVER_BTQ.seo.title}
      >
        <meta name="title" content={SCREVER_BTQ.seo.title} />
        <meta name="description" content={SCREVER_BTQ.seo.description} />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://screver.com/events/screver-btq/"
        />
        <meta property="og:title" content={SCREVER_BTQ.seo.title} />
        <meta property="og:description" content={SCREVER_BTQ.seo.description} />
        <meta
          property="og:image"
          content="https://wp.screver.com/wp-content/uploads/2023/08/screver-btq-meta-img.jpg"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://screver.com/events/screver-btq/"
        />
        <meta property="twitter:title" content={SCREVER_BTQ.seo.title} />
        <meta
          property="twitter:description"
          content={SCREVER_BTQ.seo.description}
        />
        <meta
          property="twitter:image"
          content="https://wp.screver.com/wp-content/uploads/2023/08/screver-btq-meta-img.jpg"
        />

        <meta name="theme-color" content="#191820"></meta>

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Epilogue:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div
        className={pageWrap}
        ref={scrollWrapper}
        style={{
          backgroundColor: inViewSec ? '#EAECF3' : '#191820',
          transition: 'all 0.4s ease-in-out',
        }}
      >
        <video
          playsInline
          autoPlay
          muted
          loop
          src="https://wp.screver.com/wp-content/uploads/2023/05/Vawe_Capptoo_Noise.mp4"
        ></video>
        <Header data={SCREVER_BTQ} />
        <main>
          <div
            style={{
              opacity: inViewSec ? 0 : 1,
              transition: 'all 0.4s ease-in-out',
            }}
          >
            <Hero data={SCREVER_BTQ} />
            <Answers data={SCREVER_BTQ} />
          </div>

          <div
            className=""
            ref={inViewRefSec}
            style={{
              opacity: inViewSec ? 1 : 0,
              transition: 'all 0.4s ease-in-out',
            }}
          >
            <Expect data={SCREVER_BTQ} />
            <Hair data={SCREVER_BTQ} />
            <Accordion data={SCREVER_BTQ} />
          </div>

          <div
            style={{
              opacity: inViewSec ? 0 : 1,
              transition: 'all 0.4s ease-in-out',
            }}
          >
            <Program data={SCREVER_BTQ} />
            <SeeYou data={SCREVER_BTQ} />
            <Team data={SCREVER_BTQ} />
          </div>
        </main>
        <Footer />

        <Sticky data={SCREVER_BTQ} />
      </div>
    </>
  );
};

export default ScreverBtq;
